// extracted by mini-css-extract-plugin
export var section = "howInstallmentWorks-module--section--jA5PJ";
export var halva = "howInstallmentWorks-module--halva--y2Ws4";
export var redisignBezProcentov = "howInstallmentWorks-module--redisignBezProcentov--M+0km";
export var pkwRedesign = "howInstallmentWorks-module--pkwRedesign--xA5fA";
export var stickerPay = "howInstallmentWorks-module--stickerPay--SAVAj";
export var container = "howInstallmentWorks-module--container--Z-6-P";
export var title = "howInstallmentWorks-module--title--pDMpK";
export var refin = "howInstallmentWorks-module--refin---C+s9";
export var wrapper = "howInstallmentWorks-module--wrapper--Qv1lh";
export var steps = "howInstallmentWorks-module--steps--cV6L8";
export var standard = "howInstallmentWorks-module--standard--fP5h6";
export var pkw = "howInstallmentWorks-module--pkw---OglR";
export var step = "howInstallmentWorks-module--step--8WjEi";
export var pkwOther = "howInstallmentWorks-module--pkwOther--i9hYS";
export var stepText = "howInstallmentWorks-module--stepText--3SdE8";
export var stepTitle = "howInstallmentWorks-module--stepTitle--kdxW5";
export var stepSubtitle = "howInstallmentWorks-module--stepSubtitle--B+JNJ";
export var brMobile = "howInstallmentWorks-module--brMobile--XKjF9";