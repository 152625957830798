import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    padding: "0 0 30px",
  },
  text: {
    margin: 0,
    fontSize: 14,
    lineHeight: "22px",
  },
})

export default useStyles
