import React, { useEffect, useState } from "react"

import cn from "classnames"
import clsx from "clsx"
import SwiperCore from "swiper"
import type { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import LazyLoad from "react-lazyload"

import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"

import fetchShops from "./helpers/fetchShops"
import * as styles from "./partners.module.scss"

type Shop = {
  id: number
  brand: string
  icon_url: string
  partner_site_url: string
  period: number
}

const params: SwiperOptions = {
  slidesPerView: "auto",
}

type PartnersProps = {
  darkColor?: boolean
  orderNum?: string
  title?: string
  subTitle?: string
}

export function Partners({
  darkColor,
  orderNum,
  title = "Магазины-партнеры",
  subTitle,
}: PartnersProps) {
  const [shops, setShops] = useState<Shop[]>([])

  function getShops(offset?: number) {
    fetchShops(offset).then((shops1) => {
      setShops((prev) => prev.concat(shops1))
    })
  }

  useEffect(() => {
    getShops()
  }, [])

  function onSlideNext(instance: SwiperCore) {
    const { realIndex, slides } = instance
    const partnersLen = slides.length

    if (realIndex >= partnersLen - 10) {
      getShops(partnersLen)
    }
  }

  return (
    <section
      className={clsx(styles.section, "content-visibility-auto")}
      id="partners"
      data-exclude={orderNum}
    >
      <Container>
        <div className={cn(styles.title_container, darkColor && styles.darkColor)}>
          <h2 className={styles.title}>{title}</h2>
          {subTitle && <div className={styles.subTitlePartners}> {subTitle} </div>}
        </div>
        <Container className={styles.p_0}>
          <Swiper {...params} onSlideNextTransitionEnd={onSlideNext}>
            {shops.map((data) => (
              <SwiperSlide className={styles.slide} key={data.id}>
                <Box className="text-center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={styles.logo}
                  >
                    <LazyLoad offset={100} once>
                      <img alt={data.brand} src={data.icon_url} />
                    </LazyLoad>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Container>
    </section>
  )
}
