import React from "react"
import * as styles from "./switcherTextBlock.module.scss"

export const data = [
  {
    text: "Список документов Заемщика для рассмотрения заявки на кредит",
    href: "https://prod-api.sovcombank.ru/documents/8972",
  },
  {
    text: "Документы для рассмотрения заявления на кредит по Продавцу и Объекту недвижимости",
    href: "https://prod-api.sovcombank.ru/documents/8971",
  },
  {
    text: "Список дополнительных документов для рассмотрения заявки при рефинансировании ипотечного кредита",
    href: "https://prod-api.sovcombank.ru/documents/8973",
  },
]

export const RequirementsRefin = () => (
  <div className={styles.htmlBlock}>
    <ul>
      <li>Паспорт РФ;</li>
      <li>Возраст от 20 до 75 лет;</li>
      <li>официальное трудоустройство (стаж на последнем месте работы не менее 4 месяцев);</li>
      <li>
        наличие постоянной регистрации не менее 4 последних месяцев на территории одного населенного
        пункта РФ, проживание в городе присутствия подразделения Банка или в одном из прилегающих
        населенных пунктов (но не более 70 километров от любого внутреннего структурного
        подразделения Банка).
      </li>
    </ul>
  </div>
)
