// extracted by mini-css-extract-plugin
export var section = "benefits-module--section--c9P1+";
export var title = "benefits-module--title--roMzr";
export var card = "benefits-module--card--vxHHS";
export var textBlock = "benefits-module--textBlock--xTWx8";
export var linkBlock = "benefits-module--linkBlock--vXhYP";
export var link = "benefits-module--link--fCbzZ";
export var desc = "benefits-module--desc--7H+GI";
export var videoWrapper = "benefits-module--videoWrapper--H4KYH";
export var video = "benefits-module--video--vX782";
export var playButton = "benefits-module--playButton--VTCfm";
export var card_title = "benefits-module--card_title--k6KJw";
export var card_desc = "benefits-module--card_desc--OMV5w";
export var small_card = "benefits-module--small_card--lLejF";
export var small_card_img = "benefits-module--small_card_img--sls29";
export var small_card_title = "benefits-module--small_card_title---FlBb";
export var small_card_desc = "benefits-module--small_card_desc--JV9Qr";