import React, { useEffect } from "react"
import { ImageDataLike } from "gatsby-plugin-image"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { Img } from "./Img"

import { scrollToPersonalForm } from "../../../helpers/scrollToPersonalForm"

import * as styles from "./banner.module.scss"

type BannerProps = {
  large: ImageDataLike
  mob: ImageDataLike
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
}

const defaultTitle = `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца без % на&nbsp;все!
`

const defaultSubtext = `
  — До 15% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />— Обслуживание 0 ₽
`

export function Banner({
  large,
  mob,
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultSubtext,
  orderNum,
}: BannerProps) {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: buttonText,
      placement: "middle_button_halva",
    })
    scrollToPersonalForm()
  }

  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} large={large} mob={mob} />
            <div className={styles.text}>
              <h1 className={styles.head} dangerouslySetInnerHTML={{ __html: title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <Button className={styles.btn} onClick={handleClick}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container>
          <Button onClick={handleClick} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
