import React, { type ReactNode } from "react"
import * as styles from "./howInstallmentWorks.module.scss"

interface Steps {
  title?: ReactNode
  subtitle?: ReactNode
}

export const DEFAULT_STEPS: Steps[] = [
  {
    title: (
      <>
        Оформите карту «Халва» <br />в период<span>&nbsp;с 01.10.2023 по 31.12.2023</span>
      </>
    ),
  },
  {
    title: (
      <>
        Активируйте промокод из СМС
        <span>
          &nbsp;и примените его к первой покупке от 5000 рублей заемными средствами в любом
          магазине-партнере
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        Получите кэшбэк 1000 рублей
        <br />
        <span>он поступит на бонусный счет вашей Халвы </span>
      </>
    ),
  },
]

export const STEPS_PKW: Steps[] = [
  {
    title: <>Оформите карту онлайн</>,
    subtitle: (
      <>
        Получите Халву с лимитом до 500&nbsp;000 ₽ уже завтра <b>не выходя из дома</b>
      </>
    ),
  },
  {
    title: <>Покупайте с Халвой</>,
    subtitle: (
      <>
        Вы точно найдете <b>всё, что хотите</b> от продуктов и до бытовой техники
        <b> у&nbsp;250&nbsp;000+&nbsp;партнеров</b>
      </>
    ),
  },
  {
    title: <>Наслаждайтесь покупкой</>,
    subtitle: (
      <>
        Проценты за вас <b>заплатит магазин</b>
      </>
    ),
  },
]

export const STEPS_REFIN: Steps[] = [
  {
    subtitle: (
      <>
        <b>
          Погасите свои кредитные карты
          <br />
        </b>
        на сумму до&nbsp;300&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    subtitle: (
      <>
        <b>Оплачивайте Халвой ежемесячно </b>от&nbsp;5&nbsp;покупок на&nbsp;общую&nbsp;сумму
        от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    subtitle: (
      <>
        <b>Наслаждайтесь беспроцентной рассрочкой </b>до&nbsp;24&nbsp;месяцев
      </>
    ),
  },
]

export const STEPS_HALVA: Steps[] = [
  {
    title: (
      <>
        Погасите&nbsp;свои кредиты <br className={styles.brMobile} />{" "}
        <span>на&nbsp;сумму до&nbsp;300&nbsp;000&nbsp;₽</span>
      </>
    ),
  },
  {
    title: (
      <>
        Оплачивайте&nbsp;Халвой ежемесячно{" "}
        <span>от 5 покупок на общую сумму от &nbsp;10&nbsp;000&nbsp;₽</span>
      </>
    ),
  },
  {
    title: (
      <>
        Наслаждайтесь беспроцентной <br /> рассрочкой <span>до&nbsp;24&nbsp;месяцев</span>
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_REDISIGN_BEZPROCENTOV: Steps[] = [
  {
    title: (
      <>
        <span> Получите карту.</span> В течение <br className={styles.brMobile} /> первого месяца
        рассрочка <span>у всех партнеров</span> – 24 месяца
      </>
    ),
  },
  {
    title: (
      <>
        <span> Оплачивайте&nbsp;Халвой </span> ежемесячно от 5 покупок <span> на общую сумму </span>{" "}
        от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    title: (
      <>
        <span> Наслаждайтесь</span> беспроцентной <br /> рассрочкой до&nbsp;24&nbsp;месяцев
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_PKW_REDISIGN: Steps[] = [
  {
    title: (
      <>
        <span> Получите карту.</span> В течение <br className={styles.brMobile} /> первого месяца
        рассрочка <span>у всех партнеров</span> – 24 месяца
      </>
    ),
  },
  {
    title: (
      <>
        <span> Оплачивайте&nbsp;Халвой </span> ежемесячно от 5 покупок <span> на общую сумму </span>{" "}
        от&nbsp;10&nbsp;000&nbsp;₽
      </>
    ),
  },
  {
    title: (
      <>
        <span> Не платите проценты</span> <br /> 24&nbsp;месяца
      </>
    ),
    subtitle: <></>,
  },
]

export const STEPS_STICKER_PAY: Steps[] = [
  {
    title: (
      <>
        Заберите стикер <span> в ближайшем офисе Совкомбанка в течение 10 дней</span>.
      </>
    ),
  },

  {
    title: (
      <>
        Выполните условия{" "}
        <span>
          регулярного шопинга: от&#160;5 покупок на сумму не менее 10 000 рублей за месяц.
        </span>
      </>
    ),
  },
  {
    title: (
      <>
        <span>Выполняйте этой условие каждый месяц, чтобы </span>стикер оставался бесплатным!
      </>
    ),
  },
]
