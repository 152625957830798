import React, { useMemo, type ReactNode } from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import clsx from "clsx"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { scrollToPersonalForm } from "../../helpers/scrollToPersonalForm"

import * as styles from "./moreBenefitsRedisign.module.scss"
import { TooltipQuestion } from "./Tooltip/Tooltip"
import { items } from "./helpers"

type MoreBenefitsRedesignProps = {
  additionalEventInDataLayer: boolean
  bottomBlock?: boolean
  variant?: "pkw" | "refin"
  title?: ReactNode
  subtitle?: ReactNode
  hasSubtitle?: boolean
  orderNum?: string
}

export const MoreBenefitsRedisign = ({
  additionalEventInDataLayer = false,
  bottomBlock = true,
  variant = "pkw",
  title = "Больше выгоды",
  subtitle = "С подпиской Халва.Десятка",
  hasSubtitle = true,
  orderNum,
}: MoreBenefitsRedesignProps) => {
  const handleClick = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayer({
        event: "buttonClick",
        name: "bottom_cta_desyatka",
      })
    }
    scrollToPersonalForm()
  }

  const itemsList = useMemo(
    () =>
      items[variant].map(({ Img, alt, title: itemTitle, tooltipHint, hintClassName }, i) => (
        <div key={i} className={clsx(styles.item, styles[`item${i + 1}`])}>
          <div className={styles.itemText}>
            <p>{itemTitle}</p>
            {tooltipHint && <TooltipQuestion text={tooltipHint} hintClassName={hintClassName} />}
          </div>
          <Img alt={alt} className={styles.img} />
        </div>
      )),
    [variant]
  )

  return (
    <section className={styles.section} id="moreBenefits" data-exclude={orderNum}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.textBlock}>
            <div className={styles.title}>
              <h2>{title}</h2>
            </div>
            {hasSubtitle && <div className={styles.desc}>{subtitle}</div>}
          </div>
          <div className={styles.wrap}>{itemsList}</div>
        </div>
        {bottomBlock && (
          <div className={styles.kameleoonBlock}>
            <div className={styles.kameleoonTitle}>
              Оформите Халву и получите <br /> <b> месяц подписки в подарок </b>
            </div>
            <Button className={styles.kameleoonBtn} onClick={handleClick}>
              Оформить карту
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
