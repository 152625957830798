import sendRequest from "@ecom/ui/utils/sendRequest"

export type Shop = {
  id: number
  brand: string
  icon_url: string
  partner_site_url: string
  period: number
}

interface Response {
  errorCode: number
  result: Shop[]
  status: number
}

export default function fetchShops(offset = 0, limit = 30): Promise<Shop[]> {
  return sendRequest(`${process.env.GATSBY_APP_URL_API}/v2/halva/shop`, {
    limit,
    offset,
  }).then((res: Response) => {
    if (res.status === 1) {
      return res.result
    }
    throw new Error("Fetch partners failed")
  })
}
