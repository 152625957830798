import React, { useEffect, useState } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import {
  DEFAULT_STEPS,
  STEPS_PKW,
  STEPS_REFIN,
  STEPS_HALVA,
  STEPS_REDISIGN_BEZPROCENTOV,
  STEPS_PKW_REDISIGN,
  STEPS_STICKER_PAY,
} from "./helpers"

import * as styles from "./howInstallmentWorks.module.scss"

type Props = {
  variant?:
    | "pkw"
    | "standard"
    | "refin"
    | "halva"
    | "redisignBezProcentov"
    | "pkwRedesign"
    | "stickerPay"
  orderNum?: string
  pkwOther?: boolean
}

export const HowInstallmentWorks = ({
  variant = "standard",
  orderNum,
  pkwOther = false,
}: Props) => {
  const [steps, setSteps] = useState(DEFAULT_STEPS)

  useEffect(() => {
    switch (variant) {
      case "pkw": {
        setSteps(STEPS_PKW)
        break
      }
      case "refin": {
        setSteps(STEPS_REFIN)
        break
      }
      case "standard": {
        setSteps(DEFAULT_STEPS)
        break
      }
      case "redisignBezProcentov": {
        setSteps(STEPS_REDISIGN_BEZPROCENTOV)
        break
      }
      case "pkwRedesign": {
        setSteps(STEPS_PKW_REDISIGN)
        break
      }
      case "stickerPay": {
        setSteps(STEPS_STICKER_PAY)
        break
      }
      default: {
        setSteps(DEFAULT_STEPS)
        break
      }
    }
    if (variant === "halva") {
      setSteps(STEPS_HALVA)
    }
  }, [variant])

  return (
    <section className={clsx(styles.section, styles[variant])} data-exclude={orderNum}>
      <Container className={styles.container}>
        <h2 className={clsx(styles.title, styles[variant])}>
          Как получить кэшбэк <br />
          1000 рублей
        </h2>
        <div className={styles.wrapper}>
          <ul className={clsx(styles.steps, styles[variant])}>
            {steps.map(({ title, subtitle }, i) => (
              <li
                key={i}
                className={clsx(styles.step, styles[variant], { [styles.pkwOther]: pkwOther })}
              >
                <div className={clsx(styles.stepText, styles[variant])}>
                  <p className={clsx(styles.stepTitle, styles[variant])}>{title}</p>
                  <p
                    className={clsx(styles.stepSubtitle, styles[variant], {
                      [styles.pkwOther]: pkwOther,
                    })}
                  >
                    {subtitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}
