import React, { FC, type ReactNode } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { GatsbyImageProps } from "gatsby-plugin-image"
import { GiftImage } from "./img/GiftImage"
import { HalvaCardImg } from "./img/halvaCard"
import { scrollToPersonalForm } from "../../helpers/scrollToPersonalForm"

import * as styles from "./BottomCta.module.scss"

const DEFAULT_TITLE = (
  <>
    Оформите Халву и получите <br /> <b> месяц подписки в подарок</b>
  </>
)

interface BottomCtaProps {
  buttonText?: string
  title?: ReactNode
  variant?: "refin" | "halva" | "pkwRedesign"
  orderNum?: string
  BgImg: FC<Omit<GatsbyImageProps, "image">>
}

export const BottomCta = ({
  buttonText = "Оформить карту",
  title = DEFAULT_TITLE,
  variant = "halva",
  orderNum,
  BgImg,
}: BottomCtaProps) => {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: buttonText,
      placement: "bottom_cta_desyatka",
    })

    scrollToPersonalForm()
  }

  return (
    <section className={clsx(styles.root, styles[variant])} data-exclude={orderNum}>
      <Container className={styles.container}>
        <div className={clsx(styles.wrapper, styles[variant])}>
          <BgImg alt="background image" className={styles.img} />
          <HalvaCardImg
            alt="halvaCard"
            className={clsx(styles.halvaCardImgMobile, styles[variant])}
          />
          <div className={clsx(styles.wrap, styles[variant])}>
            <GiftImage alt="gift" className={styles.giftImg} />
            <HalvaCardImg alt="halvaCard" className={styles.halvaCardImg} />
            <p className={clsx(styles.text, styles[variant])}>{title}</p>
            <Button className={styles.btn} onClick={handleClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  )
}
