import React from "react"

type PlayButtonPros = {
  className?: string
}

export function PlayButton({ className }: PlayButtonPros) {
  return (
    <svg className={className} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.5" />
      <path d="M28.0665 19.5712L16.8115 13.3335V26.6668L28.0665 19.5712Z" fill="white" />
    </svg>
  )
}
