import {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
} from "./icons"

export const ICONS: { [key: string]: () => JSX.Element } = {
  CashBackIcon,
  RemainedIcon,
  TransferIcon,
  InstallmentIcon,
  ServiceIcon,
  WithDrawingIcon,
}

export const ITEMS = [
  {
    icon: "CashBackIcon",
    title: "Кэшбэк <br/>до 10%",
  },
  {
    icon: "RemainedIcon",
    title: "До 15% <br/> на остаток",
  },
  {
    icon: "TransferIcon",
    title: "Возвращаем <br/>1000 рублей",
  },
  {
    icon: "InstallmentIcon",
    title: "Рассрочка <br/>0%",
  },
  {
    icon: "ServiceIcon",
    title: "Обслуживание <br/>бесплатно",
  },
  {
    icon: "WithDrawingIcon",
    title: "Снятие наличных бесплатно",
  },
]
