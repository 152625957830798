import React, { ChangeEvent, useEffect, useState } from "react"
import cn from "classnames"
import Container from "@ecom/ui/components/Container"
import Slider from "@material-ui/core/Slider"
import Checkbox from "@material-ui/core/Checkbox"
import Tooltip from "@material-ui/core/Tooltip"
import { Zoom } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { StaticImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { ItemTooltip } from "../ItemTooltip"
import { scrollToPersonalForm } from "../../helpers/scrollToPersonalForm"
import { pushToDataLayerOnce } from "../../helpers/pushToDataLayerOnce"

import { HalvaIcon } from "./images/HalvaIcon"
import { CheckIcon } from "./images/CheckIcon"
import { EmptyIcon } from "./images/EmptyIcon"
import { Question } from "./images/Question"
import hint_icon from "../../images/icons/icon_question.svg"
import question_icon from "../../images/icons/icon_questionV2.svg"
import close_icon from "../../images/icons/icon_closeV2.svg"

import * as styles from "./newCalculator.module.scss"

interface CalculatorProps {
  titleLocationBottom?: boolean
  titleLocationTop?: boolean
  subtitle?: React.ReactNode
  subtext?: React.ReactNode
  darkColor?: boolean
  // TODO:: migrate to new hint
  newHint?: boolean
  anotherDescr?: string
  orderNum?: string
  additionalEventInDataLayer?: boolean
}

const defSubText =
  "Вы не платите проценты, за вас это сделает магазин. Платеж делится на равные части каждый месяц. К тому же, по подписке «Халва.Десятка», срок рассрочки увеличивается до 10 месяцев у каждого партнера!"

const defSubTitle = "В чем преимущества рассрочки с Халвой?"

export const NewCalculator = ({
  titleLocationBottom = false,
  titleLocationTop = false,
  subtitle = defSubTitle,
  subtext = defSubText,
  darkColor = false,
  newHint = false,
  anotherDescr = "",
  orderNum,
  additionalEventInDataLayer = false,
}: CalculatorProps) => {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: "cta_calculator",
    })

    scrollToPersonalForm()
  }

  const [spending, setSpending] = useState<number | number[]>(50000)
  const [balance, setBalance] = useState<number | number[]>(35000)
  const [check, setCheck] = useState(true)
  const [sum, setSum] = useState(0)

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const onChangeSpending = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("monthly_spending_calc", {
        event: "buttonClick",
        name: "monthly_spending_calc",
      })
    }
    setSpending(value)
  }

  const onChangeBalance = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("account_balance_calc", {
        event: "buttonClick",
        name: "account_balance_calc",
      })
    }
    setBalance(value)
  }

  const onChangeCheckbox = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("halva_desyatka_check", {
        event: "buttonClick",
        name: "halva_desyatka_check",
      })
    }
    setCheck(!check)
  }

  useEffect(() => {
    let sumHalva

    const balancePercentage = 12
    const costDefault = Math.floor((+spending / 100) * 2)
    const balanceDefault = Math.floor((+balance / 100) * (5 / 12))
    const subscribeCost = Math.floor((+spending / 100) * 5)
    const balanceSubscribeLess = Math.floor((+balance / 100) * (balancePercentage / 12))
    const balanceSubscribeGreat = Math.floor(
      (400000 / 100) * (balancePercentage / 12) + Math.floor(((+balance - 400000) / 100) * (5 / 12))
    )

    if (check) {
      if (balance <= 400000) {
        if (Math.floor((+spending / 100) * 5) <= 5000) {
          sumHalva = subscribeCost + balanceSubscribeLess
        } else {
          sumHalva = 5000 + balanceSubscribeLess
        }
      } else if (Math.floor((+spending / 100) * 5) <= 5000) {
        sumHalva = subscribeCost + balanceSubscribeGreat
      } else {
        sumHalva = 5000 + balanceSubscribeGreat
      }

      setSum(sumHalva)
    } else {
      sumHalva = costDefault + balanceDefault
      if (Math.floor((+spending / 100) * 2) <= 5000) {
        setSum(sumHalva)
      } else {
        setSum(5000 + balanceDefault)
      }
    }
  }, [check, balance, spending])

  return (
    <section
      className={cn(styles.section, darkColor && styles.darkColor)}
      id="calculator"
      data-exclude={orderNum}
    >
      <Container>
        {titleLocationTop && (
          <div className={styles.block_header}>
            <h3 className={styles.title}>{subtitle}</h3>
            <p className={styles.title_text}>{subtext}</p>
          </div>
        )}
        <div className={styles.calcHeader}>
          <h2 className={styles.head}>Рассчитайте выгоду от&nbsp;карты «Халва»</h2>
        </div>
        {anotherDescr ? (
          <p className={styles.anotherDescr}>{anotherDescr}</p>
        ) : (
          <p className={styles.descr}>Данные рассчитываются на основе текущих пользователей.</p>
        )}
        <div className={styles.content}>
          <div className={styles.sliderContainer}>
            <p className={styles.sliderDescr}>Траты за месяц</p>
            <div className={styles.sliderSum}>{spending.toLocaleString().replace(/,/g, " ")} ₽</div>
            <Slider
              value={spending}
              onChange={onChangeSpending}
              max={150000}
              step={1000}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            <p className={styles.sliderDescr}>Остаток на счёте</p>
            <div className={styles.sliderSum}>{balance.toLocaleString().replace(/,/g, " ")} ₽</div>
            <Slider
              value={balance}
              onChange={onChangeBalance}
              max={500000}
              step={1000}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            {/* TODO:: migratge to new hint */}
            {newHint ? (
              <ItemTooltip
                icon={hint_icon}
                className={{ container: styles.checkboxContainer }}
                hint="Подписка «Халва.Десятка» дает дополнительные преимущества вашей карте. Увеличенная рассрочка до 10 месяцев, повышенный кэшбэк до 10% и до 15% на остаток. 
                    Первый месяц подписки — 0 рублей, последующие — 299 рублей."
              >
                <Checkbox
                  classes={{ root: styles.checkbox }}
                  checked={check}
                  onChange={onChangeCheckbox}
                  color="primary"
                  checkedIcon={<CheckIcon />}
                  icon={<EmptyIcon />}
                />
                <p className={styles.helperText}>
                  С подпиской <span>«Халва.Десятка»</span>
                </p>
              </ItemTooltip>
            ) : (
              <div className={styles.checkboxContainer}>
                <Checkbox
                  classes={{ root: styles.checkbox }}
                  checked={check}
                  onChange={onChangeCheckbox}
                  color="primary"
                  checkedIcon={<CheckIcon />}
                  icon={<EmptyIcon />}
                />
                <p className={styles.helperText}>
                  С подпиской <span>«Халва.Десятка»</span>
                </p>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      classes={{
                        tooltip: styles.tooltip,
                      }}
                      PopperProps={{
                        disablePortal: false,
                      }}
                      onClose={handleTooltipOpen}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <>
                          <img
                            src={question_icon}
                            alt="question"
                            className={styles.hintModalQuestion}
                          />
                          <div className={styles.hintModal}>
                            Подписка «Халва.Десятка» дает дополнительные преимущества вашей карте.
                            Увеличенная рассрочка до 10 месяцев, повышенный кэшбэк до 10% <br /> и
                            до 15% на остаток. <br /> Первый месяц подписки —&nbsp;0&nbsp;рублей,
                            последующие —&nbsp;299&nbsp;рублей.
                          </div>
                          <div
                            className={styles.close}
                            onClick={handleTooltipClose}
                            aria-hidden="true"
                          >
                            <img src={close_icon} alt="close" />
                          </div>
                        </>
                      }
                      TransitionComponent={Zoom}
                    >
                      <div
                        className={styles.hintContainer}
                        onClick={handleTooltipOpen}
                        aria-hidden="true"
                      >
                        <Question />
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>
            )}
          </div>
          <div className={styles.card} id="calculatorCard">
            <StaticImage
              alt="card"
              src="./images/card.png"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
            />
            <div className={styles.cardContainer}>
              <div className={styles.cardHeader}>
                <p className={styles.cardHead}>Ваша прибыль</p>
                <div className={styles.halvaIcon}>
                  <HalvaIcon />
                </div>
              </div>
              <div className={styles.sum}>
                {sum.toLocaleString().replace(/,/g, " ")} <span>₽</span>
              </div>
              <Button onClick={handleClick} classes={{ root: styles.btn }}>
                Оформить карту
              </Button>
            </div>
          </div>
        </div>
        {titleLocationBottom && (
          <div className={styles.block_bottom} id="textBottomCalculator">
            <h3 className={styles.title}>{subtitle}</h3>
            <p className={styles.title_text}>{subtext}</p>
          </div>
        )}
      </Container>
    </section>
  )
}
