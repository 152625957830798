import React from "react"

import {
  BalanceOfFunds,
  Car,
  CardInSuitcase,
  Cashback,
  FireExtinguisher,
  InstallmentPlan,
  MoneyTransfer,
  ThreeCards,
} from "./images"

import type { BenefitsItem } from "./types"

const hintClassNames = {
  // 10 МЕСЯЦ

  c1: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      top: -8,
      left: -16,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // ДО 12%

  c2: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 20,
      top: -30,
    },
    md: {
      left: 22,
      top: -43,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // КЭШБЭК

  c3: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      top: -5,
      left: -50,
    },
    md: {
      top: -10,
      left: -75,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // ПЕРЕВОДЫ
  c4: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      left: 22,
      top: -43,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
}

const pkwItems = [
  {
    Img: InstallmentPlan,
    alt: "ten months",
    title: (
      <>
        Увеличенная рассрочка
        <b>
          <br /> от 10 месяцев
        </b>
        <br /> у всех партнеров
      </>
    ),
    tooltipHint:
      "Увеличенная рассрочка у всех партнеров. Совершайте покупки у любых партнеров Халвы со сроком рассрочки от 10 месяцев и больше.",
    hintClassName: hintClassNames.c1,
  },
  {
    Img: BalanceOfFunds,
    alt: "twelve percents",
    title: (
      <>
        <b>
          До 15 % на остаток <br />
        </b>
        собственных средств для новых клиентов
      </>
    ),
    tooltipHint:
      "До 15% на остаток собственных средств. Получайте доход – повышенный процент на остаток своих денег в первые 3 месяца.",
    hintClassName: hintClassNames.c2,
  },
  {
    Img: Cashback,
    alt: "cashback",
    title: (
      <>
        Кэшбэк <br /> <b>до 10% </b>
      </>
    ),
    tooltipHint:
      "В том числе гарантированный кэшбэк 10% за каждую поездку на такси «Яндекс Go». С подпиской «Яндекс Плюс» – еще до 10% кэшбэка от сервиса.",
    hintClassName: hintClassNames.c3,
  },
  {
    Img: MoneyTransfer,
    alt: "money transfer",
    title: (
      <>
        Переводы <br /> <b>без комиссии</b>
      </>
    ),
    tooltipHint:
      "Переводите свои средства в приложении или личном кабинете. Увеличенный лимит – 50 000 ₽ за отчетный период  без комиссии.",
    hintClassName: hintClassNames.c4,
  },
]

const refinItems = [
  {
    Img: ThreeCards,
    alt: "three cards",
    title: (
      <>
        Рефинансирование кредитных карт
        <br />
        <b>любых банков</b>
      </>
    ),
  },
  {
    Img: FireExtinguisher,
    alt: "fire extinguisher",
    title: (
      <>
        <b>Просто и быстро</b>
        <br />
        погасим задолженности за&nbsp;вас
      </>
    ),
  },
  {
    Img: CardInSuitcase,
    alt: "card in suitcase",
    title: (
      <>
        Возможность <b>досрочного погашения</b> без&nbsp;комиссий
      </>
    ),
  },
  {
    Img: Car,
    alt: "car",
    title: (
      <>
        <b>Дистанционное оформление</b>
        <br />и бесплатная доставка Халвы
      </>
    ),
  },
]

export const items: Record<"pkw" | "refin", BenefitsItem[]> = {
  pkw: pkwItems,
  refin: refinItems,
}
