import React from "react"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import { CountDown } from "./CountDown"

import { Gift } from "./icons"
import close_hint from "../../../../images/icons/icon_hint_black.svg"

import { scrollToPersonalForm } from "../../../../helpers/scrollToPersonalForm"

import * as styles from "./rightSection.module.scss"
import { ItemTooltip } from "../../../ItemTooltip"

const handleClick = () => {
  pushToDataLayer({ event: "buttonClick", name: "Оформить карту", placement: "top_button_halva" })
  scrollToPersonalForm()
}

type Props = {
  withoutHintAndCountDown?: boolean
  onlyGift?: boolean
  showHint?: boolean
  hasCTA?: boolean
  countDownTime: number
  buttonText?: string
}

const hint =
  "Оформите карту «Халва» сейчас и получите подписку «Халва.Десятка» в подарок. Раскройте все возможности Халвы по максимуму!"

export const RightSection = ({
  withoutHintAndCountDown = false,
  onlyGift,
  showHint,
  hasCTA,
  countDownTime,
  buttonText,
}: Props) => (
  <div className={styles.container}>
    {!withoutHintAndCountDown && (
      <>
        {!onlyGift && (
          <div className={styles.halvaTenContainer}>
            <ItemTooltip
              hint={hint}
              leftPosition="-100px"
              className={{ icon: styles.hint }}
              showHint={showHint}
              hasCTA={hasCTA}
              icon={close_hint}
            >
              Халва.Десятка <br /> в подарок
            </ItemTooltip>
          </div>
        )}
        <div className={styles.countdown_container}>
          <Gift />
          <CountDown countDownTime={countDownTime} />
        </div>
      </>
    )}

    <Button classes={{ root: styles.btn }} onClick={handleClick}>
      {buttonText}
    </Button>
  </div>
)
