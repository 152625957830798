import React, { useEffect } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { scrollToPersonalForm } from "../../../helpers/scrollToPersonalForm"
import Img from "./Img"

import * as styles from "./banner.module.scss"

type BannerProps = {
  darkColor?: boolean
  buttonText?: string
  title?: string
  variant?: "pkw" | "notpkw"
  orderNum?: string
}

const defaultTitle = `
  Промокод 1000 ₽<br/>
  на карту "Халва"<br/>
  для пользователей VK
`

export function Banner({
  darkColor,
  buttonText = "Оформить карту",
  title = defaultTitle,
  variant = "pkw",
  orderNum,
}: BannerProps) {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: buttonText,
      placement: "middle_button_halva",
    })

    scrollToPersonalForm()
  }

  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={clsx(styles.container, darkColor && styles.darkColor)}>
          <div className={clsx(styles.secondContainer, styles.bannerShow)}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 className={styles.head} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <Button className={styles.btn} onClick={handleClick}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(variant !== "pkw" && styles.fixBottom, styles.btnBlock)}>
        <Container>
          <Button onClick={handleClick} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
