// extracted by mini-css-extract-plugin
export var section = "switcherTextBlock-module--section--dcuVt";
export var indent = "switcherTextBlock-module--indent--JivjQ";
export var switcherTitle = "switcherTextBlock-module--switcherTitle--KqwgR";
export var info_title_container = "switcherTextBlock-module--info_title_container--GuFsD";
export var base = "switcherTextBlock-module--base--qYQLE";
export var active = "switcherTextBlock-module--active--r4JHc";
export var info_title = "switcherTextBlock-module--info_title--rQtsQ";
export var htmlBlock = "switcherTextBlock-module--htmlBlock--CxKJI";
export var aboutBlock = "switcherTextBlock-module--aboutBlock--TPEq0";
export var aboutTitle = "switcherTextBlock-module--aboutTitle--bDPqW";
export var aboutText = "switcherTextBlock-module--aboutText--22EzG";
export var listQuestion = "switcherTextBlock-module--listQuestion--WmThM";
export var listAnswer = "switcherTextBlock-module--listAnswer--xN5+e";
export var listAnswerPadding = "switcherTextBlock-module--listAnswerPadding--b+02e";
export var displayNone = "switcherTextBlock-module--displayNone--5c+3p";
export var additionalText = "switcherTextBlock-module--additionalText--jX52b";
export var documentsBlock = "switcherTextBlock-module--documentsBlock--dDhKS";
export var documentsBlockBorder = "switcherTextBlock-module--documentsBlockBorder--TPObr";
export var documentsLink = "switcherTextBlock-module--documentsLink--wxttn";
export var documentsSize = "switcherTextBlock-module--documentsSize--6CHTr";
export var table = "switcherTextBlock-module--table--IN7rs";
export var table__cell = "switcherTextBlock-module--table__cell--t4E+7";
export var table__bold = "switcherTextBlock-module--table__bold--4SvMe";
export var subtitle = "switcherTextBlock-module--subtitle--W0oej";