// extracted by mini-css-extract-plugin
export var p_0 = "partners-module--p_0--Wqrue";
export var section = "partners-module--section---r2K8";
export var title_container = "partners-module--title_container--sVYzB";
export var darkColor = "partners-module--darkColor--uBh6C";
export var title = "partners-module--title--1uHSH";
export var sub_title = "partners-module--sub_title--Qe4bu";
export var slide = "partners-module--slide--QyU-L";
export var logo = "partners-module--logo--UG7Y3";
export var text = "partners-module--text--yE5nr";
export var subTitlePartners = "partners-module--subTitlePartners--wSEnC";