import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

const fieldsType = {
  fio: "formatFIO",
  phone: "phone",
  impartner: "impartner",
  birthDate: "date",
}
export interface DadataFio {
  dadataValue: {
    name: string
  }
  inputValue: string
  isDadataValueActual: boolean
}
export interface PersonalInfoFormData {
  fio: DadataFio
  phone: string
  agree: boolean
}

export interface PersonalInfoFormDataFormatted extends Omit<PersonalInfoFormData, "fio"> {
  name: string
  impartner: string
}

export function formatValuesToRequest(values: PersonalInfoFormData): PersonalInfoFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)
  const { surname, name, patronymic } = valuesToRequest.fio.dadataValue.data

  valuesToRequest.additionalData = getAdditionalData()
  delete valuesToRequest.fio

  return { surname, name, patronymic, ...valuesToRequest }
}
