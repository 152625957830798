// extracted by mini-css-extract-plugin
export var section = "newCalculator-module--section--IrcAC";
export var darkColor = "newCalculator-module--darkColor--0Ayc7";
export var block_header = "newCalculator-module--block_header--rRFcK";
export var block_bottom = "newCalculator-module--block_bottom--yRNab";
export var title = "newCalculator-module--title--7wPlk";
export var offer = "newCalculator-module--offer--kWeLj";
export var calcHeader = "newCalculator-module--calcHeader--4ZsQx";
export var head = "newCalculator-module--head--qzFCn";
export var descr = "newCalculator-module--descr--dyvyU";
export var anotherDescr = "newCalculator-module--anotherDescr--nXufH";
export var content = "newCalculator-module--content--sV5b5";
export var sliderContainer = "newCalculator-module--sliderContainer--6Sl+B";
export var sliderDescr = "newCalculator-module--sliderDescr--eecVS";
export var sliderSum = "newCalculator-module--sliderSum--1HRbo";
export var slider = "newCalculator-module--slider--SKo9z";
export var track = "newCalculator-module--track--rxB64";
export var rail = "newCalculator-module--rail--7jrug";
export var thumb = "newCalculator-module--thumb--Bbfl1";
export var card = "newCalculator-module--card--Dxoql";
export var cardContainer = "newCalculator-module--cardContainer--OEMK7";
export var cardHeader = "newCalculator-module--cardHeader--gLALw";
export var cardHead = "newCalculator-module--cardHead--6ywNb";
export var sum = "newCalculator-module--sum--l60xL";
export var halvaIcon = "newCalculator-module--halvaIcon--qh6uE";
export var checkboxContainer = "newCalculator-module--checkboxContainer--h6t0u";
export var checkbox = "newCalculator-module--checkbox--l4wth";
export var helperText = "newCalculator-module--helperText--I7N-V";
export var btn = "newCalculator-module--btn--n5N54";
export var hintContainer = "newCalculator-module--hintContainer--OWZij";
export var close = "newCalculator-module--close--55-1w";
export var hintModal = "newCalculator-module--hintModal--yiCCJ";
export var hintModalQuestion = "newCalculator-module--hintModalQuestion--rpInH";
export var tooltip = "newCalculator-module--tooltip--hjxXN";