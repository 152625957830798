import React from "react"
import * as styles from "./switcherTextBlock.module.scss"

import { items } from "./helpers/items"
import { Variant } from "./types"

type ContentProps = {
  variant?: Variant
}

const Content = ({ variant = "standard" }: ContentProps) => (
  <div className={styles.htmlBlock}>
    {items[variant].map((item) => (
      <div className={styles.aboutBlock}>
        <p className={styles.aboutTitle}>{item.title}</p>
        <p className={styles.aboutText}>{item.text}</p>
      </div>
    ))}
  </div>
)

export default Content
