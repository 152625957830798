// extracted by mini-css-extract-plugin
export var section = "banner-module--section---fQV1";
export var container = "banner-module--container--QLzRw";
export var secondContainer = "banner-module--secondContainer--7vv52";
export var darkColor = "banner-module--darkColor--SIKdu";
export var img = "banner-module--img--n3IRQ";
export var text = "banner-module--text--ULZNA";
export var head = "banner-module--head--IKlDT";
export var fixBottom = "banner-module--fixBottom--GFIhU";
export var description = "banner-module--description--zXwXs";
export var red = "banner-module--red--b596r";
export var btnBlock = "banner-module--btnBlock--39TTa";
export var mobBtn = "banner-module--mobBtn--ICD7X";
export var hideMobBlock = "banner-module--hideMobBlock--HHaYo";
export var btn = "banner-module--btn--062bp";
export var bannerShow = "banner-module--bannerShow--xVh0a";