import React from "react"

export const Close = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.94 8 4.2-4.193a.67.67 0 1 0-.947-.947L8 7.06l-4.193-4.2a.67.67 0 0 0-.947.947L7.06 8l-4.2 4.193a.667.667 0 0 0 .217 1.093.666.666 0 0 0 .73-.146L8 8.94l4.193 4.2a.665.665 0 0 0 .947 0 .666.666 0 0 0 0-.947L8.94 8Z"
      fill="currentColor"
    />
  </svg>
)
