// extracted by mini-css-extract-plugin
export var root = "BottomCta-module--root--olrjm";
export var pkwRedesign = "BottomCta-module--pkwRedesign--q+xH1";
export var container = "BottomCta-module--container--Y4plt";
export var wrapper = "BottomCta-module--wrapper--YvEhG";
export var halva = "BottomCta-module--halva--U7tP0";
export var refin = "BottomCta-module--refin--EfW1m";
export var wrap = "BottomCta-module--wrap--zMwAO";
export var halvaCardImg = "BottomCta-module--halvaCardImg--zgRgk";
export var halvaCardImgMobile = "BottomCta-module--halvaCardImgMobile--Nxyk9";
export var giftImg = "BottomCta-module--giftImg--wNXyF";
export var text = "BottomCta-module--text--XupXu";
export var btn = "BottomCta-module--btn--voAAa";
export var img = "BottomCta-module--img--+axnZ";