import React, { useEffect, useState } from "react"

import Header from "@ecom/ui/components/Header/HeaderContainer"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { BlackHalvaLogo, RedHalvaWithSovcombank } from "../Logos"
import { RightSection } from "./RightSection/RightSection"

import { pushToDataLayerOnce } from "../../../helpers/pushToDataLayerOnce"

const options = {
  rootMargin: "0px 0px -50% 0px",
  threshold: 0,
}

export type HeaderProps = {
  children?: React.ReactNode
  buttonText?: string
  withoutHintAndCountDown?: boolean
  onlyGift?: boolean
  showHint?: boolean
  hasCTA?: boolean
  additionalEventInDataLayer?: boolean
  noRightSection?: boolean
  noShadow?: boolean
  countDownTime?: number
  withNav?: boolean
  redLogoWithSovcombank?: boolean
  withButton?: boolean
}

export const NewHeader = ({
  children,
  buttonText = "Оформить карту",
  withoutHintAndCountDown = false,
  onlyGift = false,
  showHint = true,
  hasCTA = false,
  additionalEventInDataLayer = false,
  noRightSection = false,
  noShadow = true,
  countDownTime = 1800000,
  withNav = false,
  redLogoWithSovcombank = false,
  withButton = true,
}: HeaderProps) => {
  const logo = redLogoWithSovcombank ? RedHalvaWithSovcombank : BlackHalvaLogo

  const [isSticky, setIsSticky] = useState(false)
  const isStickyHeader = useMediaQuery("(min-width:599px)")
  const onLogoClick = () => {
    if (additionalEventInDataLayer) {
      pushToDataLayerOnce("logo-header", { event: "buttonClick", name: "logo-header" })
    }
    window.open("https://halvacard.ru/", "_blank")
  }

  function callback(entries: IntersectionObserverEntry[]) {
    const [entry] = entries
    setIsSticky(!entry.isIntersecting)
  }

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver
    const targetEl = document.getElementById("app")

    if (isSupported) {
      observer = new IntersectionObserver(callback, options)

      if (targetEl && isSupported) {
        observer.observe(targetEl)
      }
    }

    return () => {
      if (targetEl && isSupported) {
        observer.unobserve(targetEl)
      }
    }
  }, [])

  const rightSection = (
    <RightSection
      countDownTime={countDownTime}
      withoutHintAndCountDown={withoutHintAndCountDown}
      onlyGift={onlyGift}
      showHint={showHint}
      hasCTA={hasCTA}
      buttonText={buttonText}
    />
  )

  return (
    <Header
      withNav={withNav}
      withButton={withButton}
      withPhone={false}
      right={countDownTime > 0 && !noRightSection && rightSection}
      onLogoClick={onLogoClick}
      noShadow={noShadow}
      Logo={logo}
      sticky={isStickyHeader && isSticky}
    >
      {children}
    </Header>
  )
}
