// extracted by mini-css-extract-plugin
export var container = "banner-module--container--lFQO5";
export var img = "banner-module--img--6pf1G";
export var secondContainer = "banner-module--secondContainer--gvrki";
export var text = "banner-module--text--5MwcZ";
export var head = "banner-module--head--x25bA";
export var subText = "banner-module--subText---TRNn";
export var fixBottom = "banner-module--fixBottom--xx7-A";
export var btnBlock = "banner-module--btnBlock--qY4qR";
export var mobBtn = "banner-module--mobBtn--iFHOx";
export var hideMobBlock = "banner-module--hideMobBlock--u9Gb+";
export var btn = "banner-module--btn--PdwNZ";