import React from "react"
import { Item, Variant } from "../types"

const DEFAULT_ITEMS: Item[] = [
  { title: <>Плата за обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Плата за выпуск</>, text: <>0 ₽</> },
  {
    title: (
      <>
        Возобновляемый кредитный <br /> лимит
      </>
    ),
    text: <>до 500 000 ₽ в течение 5 лет</>,
  },
  {
    title: (
      <>
        Ставка в течение льготного периода <br /> (36&nbsp;мес.)
      </>
    ),
    text: <>0 ₽ годовых</>,
  },
  { title: <>Кэшбэк</>, text: <>до 10% с подпиской «Халва.Десятка»</> },
  { title: <>Процент на остаток</>, text: <>до 15% годовых с подпиской «Халва.Десятка»</> },
]

const DEBET_ITEMS: Item[] = [
  { title: <>Плата за обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Плата за выпуск</>, text: <>0 ₽</> },
  {
    title: (
      <>
        Возобновляемый кредитный <br /> лимит
      </>
    ),
    text: <>до 500 000 ₽ в течение 5 лет</>,
  },
  {
    title: (
      <>
        Ставка в течение льготного периода <br /> (36&nbsp;мес.)
      </>
    ),
    text: <>0 ₽ годовых</>,
  },
  { title: <>Кэшбэк</>, text: <>до 10% с подпиской «Халва.Десятка»</> },
  { title: <>Процент на остаток</>, text: <>до 15% годовых с подпиской «Халва.Десятка»</> },
]

const ABOUT_CREDIT_ITEMS: Item[] = [
  { title: <>Выпуск и обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Возобновляемый заемный лимит</>, text: <>До 500 000 рублей</> },
  { title: <>На весь срок рассрочки</>, text: <>0% годовых</> },
  { title: <>Кэшбэк</>, text: <>до 10%</> },
  { title: <>На остаток своих средств</>, text: <>до 15% доход</> },
]

const BENEFITS_CREDIT_ITEMS: Item[] = [
  { title: <>Кэшбэк за ЖКХ</>, text: <>1%</> },
  { title: <>Кэшбэк в «Яндекс Go»</>, text: <>10%</> },
  { title: <>По вкладам от 6 месяцев</>, text: <>+1%</> },
  { title: <>За приведенного друга</>, text: <>До 1000 рублей</> },
  { title: <>На остаток своих средств</>, text: <>Акции от партнеров</> },
  { title: <>Курьером по удобному адресу</>, text: <>Бесплатная доставка</> },
  { title: <>Еще больше выгоды</>, text: <>Программа лояльности</> },
]

const REFIN_CONDITIONS_ITEMS: Item[] = [
  { title: <>Беспроцентный период</>, text: <>до 24 месяцев</> },
  { title: <>Общая сумма рефинансирования</>, text: <>до 300 000 рублей</> },
  { title: <>Кредитный лимит</>, text: <>возобновляемый</> },
  { title: <>Плата за обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Плата за выпуск</>, text: <>0 ₽</> },
  { title: <>Пополнение</>, text: <>бесплатно</> },
  { title: <>Досрочное погашение задолженности</>, text: <>бесплатно</> },
  { title: <>СМС и Push-уведомления</>, text: <>бесплатно</> },
]

const TARIFFS: Item[] = [
  { title: <>Плата за обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Плата за выпуск</>, text: <>0 ₽</> },
  {
    title: (
      <>
        Возобновляемый кредитный <br /> лимит
      </>
    ),
    text: <>до 500 000 ₽ в течение 5 лет</>,
  },
  {
    title: <>Беспроцентный период</>,
    text: <>До 10 месяцев</>,
  },
  { title: <>Пополнение</>, text: <>Бесплатно</> },
  {
    title: <>Снятие наличных</>,
    text: <>Бесплатно в рассрочку на 3 месяца с подпиской «Халва.Десятка»</>,
  },
  {
    title: <>Досрочное погашение задолженности</>,
    text: <>Бесплатно</>,
  },
  {
    title: <>Переводы заемных средств</>,
    text: <>Без комиссии в рассрочку на 3 месяца с подпиской «Халва.Десятка»</>,
  },
  { title: <>Минимальный платеж</>, text: <>3,9% от задолженности</> },
  { title: <>СМС или Push-уведомления</>, text: <>Бесплатно</> },
]

const ABOUT_CREDIT_WITHOUT_PERCENT = [
  { title: <>Беспроцентный период</>, text: <> до 24 месяцев</> },
  { title: <>Возобновляемый кредитный лимит</>, text: <>до 500 000 ₽ в течение 5 лет</> },
  { title: <>Общая сумма рефинансирования</>, text: <>до 300 000 рублей</> },
  { title: <>Кэшбэк</>, text: <>до 10% с подпиской «Халва.Десятка»</> },
  { title: <>Процент на остаток </>, text: <>до 15% годовых с подпиской «Халва.Десятка»</> },
  { title: <>Плата за обслуживание</>, text: <>0 ₽ в год</> },
  { title: <>Плата за выпуск</>, text: <> 0 ₽</> },
  { title: <>Пополнение </>, text: <>бесплатно</> },
  { title: <>Досрочное погашение задолженности</>, text: <>бесплатно</> },
  { title: <>СМС или Push-уведомления</>, text: <>бесплатно</> },
]

export const items: Record<Variant, Item[]> = {
  aboutCredit: ABOUT_CREDIT_ITEMS,
  benefitsCredit: BENEFITS_CREDIT_ITEMS,
  refinСonditions: REFIN_CONDITIONS_ITEMS,
  tariffs: TARIFFS,
  standard: DEFAULT_ITEMS,
  aboutCardWithoutPercent: ABOUT_CREDIT_WITHOUT_PERCENT,
  debet: DEBET_ITEMS,
}
