/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import { SocialLinks } from "./SocialLinks"
import { StoreLinks } from "./StoreLinks"

import * as styles from "./newFooter.module.scss"

type Props = {
  ligal?: string
  isRedButton?: boolean
  withSocialLinks?: boolean
  withStoreLinks?: boolean
  withFixedButton?: boolean
  orderNum?: string
}

export const Footer = ({
  ligal = "",
  isRedButton,
  withSocialLinks = false,
  withStoreLinks = false,
  withFixedButton = true,
  orderNum,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <Container>
      <div
        className={withFixedButton ? styles.wrapperLarge : styles.wrapperSmall}
        id="footer"
        data-exclude={orderNum}
      >
        {withSocialLinks && <SocialLinks />}
        <div
          className={clsx(styles.text, !expanded && styles.hide)}
          dangerouslySetInnerHTML={{ __html: ligal }}
          id="ligal"
        />
        <button
          type="button"
          onClick={() => setExpanded((prev) => !prev)}
          className={clsx(styles.button, isRedButton ? styles.redButton : "")}
        >
          {expanded ? "Скрыть" : "Подробнее"}
        </button>

        <div className={styles.block}>
          <p className={styles.text}>
            © 2004-{new Date().getFullYear()}, ПАО «Совкомбанк». Все права защищены. <br />
            Генеральная лицензия Банка России №963 <br className={styles.br} />
            от 5 декабря 2014 г.
          </p>
          {withStoreLinks && <StoreLinks />}
        </div>
      </div>
    </Container>
  )
}
