import React from "react"
import * as styles from "./switcherTextBlock.module.scss"

export const TableDescription = () => (
  <>
    <br />
    <br />
    <table className={styles.table}>
      <tbody>
        <tr>
          <td className={styles.table__cell}>🔵 Минимальная ставка займа</td>
          <td className={styles.table__bold}>0%</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td className={styles.table__cell}>🔴 Минимальная сумма займа</td>
          <td className={styles.table__bold}>0,1 рублей</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td className={styles.table__cell}>🔵 Максимальная сумма займа</td>
          <td className={styles.table__bold}>500 000 рублей</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td className={styles.table__cell}>🔴 Максимальный срок без процентов</td>
          <td className={styles.table__bold}>До 10 месяцев</td>
        </tr>
      </tbody>
    </table>
  </>
)
