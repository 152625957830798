// extracted by mini-css-extract-plugin
export var section = "moreBenefitsRedisign-module--section--MA9u6";
export var wrapper = "moreBenefitsRedisign-module--wrapper--vseFm";
export var textBlock = "moreBenefitsRedisign-module--textBlock--cfM-4";
export var title = "moreBenefitsRedisign-module--title--6F1xa";
export var desc = "moreBenefitsRedisign-module--desc--l-NGf";
export var wrap = "moreBenefitsRedisign-module--wrap--S0Xbg";
export var item1 = "moreBenefitsRedisign-module--item1--NHaKK";
export var item2 = "moreBenefitsRedisign-module--item2--RiyR7";
export var item3 = "moreBenefitsRedisign-module--item3--dljXV";
export var item4 = "moreBenefitsRedisign-module--item4--l8yoO";
export var item = "moreBenefitsRedisign-module--item--BAxG0";
export var itemText = "moreBenefitsRedisign-module--itemText--6a1zS";
export var img = "moreBenefitsRedisign-module--img--aRR1J";
export var kameleoonBlock = "moreBenefitsRedisign-module--kameleoonBlock--2sIu0";
export var kameleoonTitle = "moreBenefitsRedisign-module--kameleoonTitle--FQGux";
export var kameleoonBtn = "moreBenefitsRedisign-module--kameleoonBtn--5oIiq";