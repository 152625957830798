import React from "react"
import * as styles from "./switcherTextBlock.module.scss"

export const data = [
  {
    text: "Список документов Заемщика для рассмотрения заявки на кредит",
    href: "https://prod-api.sovcombank.ru/documents/8972",
  },
  {
    text: "Документы для рассмотрения заявления на кредит по Продавцу и Объекту недвижимости",
    href: "https://prod-api.sovcombank.ru/documents/8971",
  },
  {
    text: "Список дополнительных документов для рассмотрения заявки при рефинансировании ипотечного кредита",
    href: "https://prod-api.sovcombank.ru/documents/8973",
  },
]

export const Requirements = ({ subText = true }: { subText?: boolean }) => (
  <div className={styles.htmlBlock}>
    {subText && <h3>Требования к заемщику</h3>}
    <ul>
      {subText && <li>Гражданство РФ;</li>}
      <li>Паспорт РФ*;</li>
      <li>Возраст от 20 до 75 лет;</li>
      <li>Официальное трудоустройство (стаж на последнем месте работы не менее 4 месяцев);</li>
      <li>
        Наличие постоянной регистрации не менее 4 последних месяцев на территории одного населенного
        пункта РФ, проживание в городе присутствия подразделения Банка или в одном из прилегающих
        населенных пунктов (но не более 70 километров от любого внутреннего структурного
        подразделения Банка);
      </li>
      {subText && <li>Наличие личного мобильного телефона.</li>}
    </ul>
    {subText && (
      <p className={styles.additionalText}>
        * При необходимости или недостаточности сведений Банк имеет право запросить дополнительную
        информацию о клиенте (документы, подтверждающие владение имуществом: квартирой, транспортным
        средством и т. д.), в том числе и дополнительные документы (второй документ, удостоверяющий
        личность; документы, подтверждающие доход клиента).
      </p>
    )}
  </div>
)
