import React, { useState } from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import { TableDescription } from "./TableDescription"
import { ContentProps, generateContent } from "./helpers/helpers"

import * as styles from "./switcherTextBlock.module.scss"

export type Item = {
  title: string
  html: string | JSX.Element
}

export type SwitcherTextBlockProps = {
  title?: string
  seoBlockText: ContentProps["seoBlockText"]
  mode?: ContentProps["mode"]
  orderNum?: string
  withTableDescription?: boolean
  blockTitle?: boolean
  indent?: boolean
}

export function SwitcherTextBlock({
  title = "",
  seoBlockText,
  mode,
  orderNum,
  withTableDescription = false,
  blockTitle = true,
  indent = false,
}: SwitcherTextBlockProps) {
  const [activeIndex, setActiveIndex] = useState(0)

  const items = generateContent({ mode, seoBlockText })

  const onClickHandler = (i: number) => {
    setActiveIndex(i)
  }

  return (
    <section
      data-testid="info"
      data-exclude={orderNum}
      className={clsx(styles.section, { [styles.indent]: indent })}
      id="info"
    >
      <Container>
        {blockTitle && <h2 className={styles.switcherTitle}>{`Полезная информация ${title}`}</h2>}
        <div data-testid="container" className={styles.info_title_container}>
          {items.map((item, i) => (
            <>
              {!seoBlockText && item.title === "Описание" ? null : (
                <button
                  key={i}
                  type="button"
                  onClick={() => onClickHandler(i)}
                  className={clsx(styles.base, activeIndex === i ? styles.active : null)}
                  data-testid={`btn-id-${i}`}
                >
                  <div className={styles.info_title}>{item.title}</div>
                </button>
              )}
            </>
          ))}
        </div>
        {items.map((item, i) =>
          typeof item.html === "string" ? (
            <>
              <div
                key={i}
                dangerouslySetInnerHTML={{ __html: item.html }}
                className={clsx(styles.htmlBlock, activeIndex === i ? null : styles.displayNone)}
                data-testid={`info-id-${i}`}
              />
              {item.title === "Описание" && withTableDescription && (
                <div
                  className={clsx(styles.htmlBlock, activeIndex === i ? null : styles.displayNone)}
                >
                  <TableDescription />
                </div>
              )}
            </>
          ) : (
            <div
              key={i}
              className={activeIndex === i ? null : styles.displayNone}
              data-testid={`info-id-${i}`}
            >
              {item.html}
            </div>
          )
        )}
      </Container>
    </section>
  )
}
