import React from "react"
import cn from "classnames"
import ReactPlayer from "react-player/lazy"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import Container from "@ecom/ui/components/Container"
import { PlayButton } from "./PlayButton"

import * as styles from "./benefits.module.scss"

type Props = {
  orderNum?: string
}

export const Benefits = ({ orderNum }: Props) => {
  const handleClickInstruction = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: "instruction",
      placement: "middle_button_cash",
    })
  }

  const link = (
    <a
      target="_blank"
      href="https://hva.im/wpc"
      className={styles.link}
      onClick={handleClickInstruction}
      rel="noreferrer"
    >
      ссылке
    </a>
  )

  return (
    <section className={styles.section} data-exclude={orderNum} id="benefits">
      <Container>
        <div>
          <div className={cn(styles.card)}>
            <div className={styles.textBlock}>
              <h3 className={styles.title}>Как активировать и использовать промокод</h3>
              <p className={styles.desc}>Активация промокода займет 2-3 минуты.</p>
              <p className={styles.linkBlock}>Перейдите по {link}, чтобы открыть инструкцию</p>
            </div>
            <div className={styles.videoWrapper}>
              <ReactPlayer
                className={styles.video}
                playing
                playsinline
                loop
                controls
                playIcon={<PlayButton className={styles.playButton} />}
                light="/image-min.jpg"
                url="/video.mp4"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
