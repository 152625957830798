import React, { ReactElement } from "react"

import Container from "@ecom/ui/components/Container"
import { ITEMS } from "./helpers"
import { IconComp } from "./IconComp"

import * as styles from "./cardWithMaxBenefits.module.scss"

const defaultTitle = "Преимущества с Халвой:"

type Props = {
  alignTitleLeft?: boolean
  orderNum?: string
  headTitle?: string | ReactElement
}

export const CardWithMaxBenefits = ({
  alignTitleLeft = false,
  orderNum,
  headTitle = defaultTitle,
}: Props) => (
  <section className={styles.section} data-exclude={orderNum}>
    <Container>
      <h2 className={alignTitleLeft ? styles.titleLeft : styles.title}>{headTitle}</h2>
      <div className={styles.blocks}>
        {ITEMS.map((item, i) => (
          <div key={i} className={styles.block}>
            <div className={styles.icon}>
              <IconComp icon={item.icon} />
            </div>
            <h4 dangerouslySetInnerHTML={{ __html: item.title }} className={styles.subTitle} />
          </div>
        ))}
      </div>
    </Container>
  </section>
)
