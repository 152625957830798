import React from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import AcceptmentField from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"

import sendRequest from "@ecom/ui/utils/sendRequest"
import { formatValuesToRequest } from "./helpers/formatToRequest"
import * as styles from "./form.module.scss"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"
import { DLGAFormEvent } from "../../../helpers/WatcherDL/DLEvents"

type Props = {
  orderNum?: string
  productName?: string
}

export function Form({ orderNum, productName = "КК Халва" }: Props) {
  function onSubmit(values: any) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    const formattedToReq = formatValuesToRequest(values)
    const additionalData = getAdditionalData()
    const apiUrl = `${process.env.GATSBY_APP_URL_API}/v2/app/public/start`

    sendRequest(apiUrl, {
      ...formattedToReq,
      additionalData,
      productName,
    })
      .then((response: { status: string }) => {
        if (response.status === "repeated") {
          pushToDataLayer({
            event: "applicationSend",
            result: "repeated",
          })
          navigate("/VKoffer/error")
        } else if (response.status === "reject") {
          pushToDataLayer({
            event: "applicationSend",
            result: "repeated",
          })
          navigate("/VKoffer/error")
        } else {
          pushToDataLayer({
            event: "applicationSend",
            result: "success",
          })
          DLGAFormEvent({
            eventCategory: "sendForm",
            eventAction: "personalForm",
            placement: "main",
          })
          navigate("/VKoffer/success")
        }
      })
      .catch(() => {
        pushToDataLayer({
          event: "applicationSend",
          result: "error",
        })
        navigate("/VKoffer/error")
      })
  }

  const onChangeFields = {
    phone: () => {
      handleClickForm()
    },
    fio: () => {
      handleClickForm()
    },

    birthDate: () => {
      handleClickForm()
    },
  }

  return (
    <section className={styles.rootform} id="form" data-exclude={orderNum}>
      <div className={styles.content}>
        <div className={styles.containerfluid}>
          <Container className={styles.container}>
            <h1 className={clsx(styles.head, "text-center")}>
              Оформите карту сейчас – <br /> получите подарок!
            </h1>
            <p className={clsx(styles.subTitle, "text-center")}>
              Быстрая и бесплатная доставка или самовывоз
            </p>
            <FormLanding
              onSubmit={onSubmit}
              className={styles.newform}
              onChangeFields={onChangeFields}
            >
              <PhoneField name="phone" className={styles.input} />
              <DadataFio label="Фамилия Имя Отчество" name="fio" className={styles.input} />
              <DateField
                name="birthDate"
                placeholder="дд.мм.гггг"
                max={new Date()}
                validAgeMin={18}
                validAgeMax={85}
                label="Дата рождения"
                className={styles.input}
              />
              <AcceptmentField name="agree" className={styles.accept} />
              <div className="text-center">
                <SubmitButton className={styles.btn}>Оформить карту</SubmitButton>
              </div>
            </FormLanding>
          </Container>
        </div>
      </div>
    </section>
  )
}
